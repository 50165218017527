import { useContext } from "react";
import PageContext from "src/components/Context/PageContext";
import { COUNTRIES, useCountry } from "src/components/Context/CountryContext";

const defaultOnlineLocationByCountry: { [key in COUNTRIES]: number } = {
  [COUNTRIES.NZ]: 30,
  [COUNTRIES.AU]: 115,
};

const defaultSupportOfficeLocationByCountry: { [key in COUNTRIES]: number } = {
  [COUNTRIES.NZ]: 10,
  [COUNTRIES.AU]: 1,
};

type CurrentUserStoreResult = {
  onlineLocationId: number;
  currentLocationId: number;
  inventoryLocationId: number;
  isSupportOffice: boolean;
};

export const useCurrentUserStore = (): CurrentUserStoreResult => {
  const { country } = useCountry();

  const [contextData] = useContext(PageContext);
  const { userProfile } = contextData;

  const supportOfficeLocation = defaultSupportOfficeLocationByCountry[country];

  return {
    onlineLocationId: defaultOnlineLocationByCountry[country],
    currentLocationId: (userProfile?.storeNumber) ? userProfile.storeNumber : supportOfficeLocation,
    inventoryLocationId: (userProfile?.storeNumber) ? userProfile.storeNumber: supportOfficeLocation,
    isSupportOffice: (userProfile?.storeNumber) ? userProfile.storeNumber === supportOfficeLocation : true
  };
};
